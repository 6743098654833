<template>
  <el-dialog
    id="EditRestrictOrgDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="420px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    @keydown.enter.native="onCommit"
    destroy-on-close
  >
    <el-form ref="EditRestrictOrgForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:180px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="name" :label="$t('Назва')">
          <el-input v-model="model.name" ref="nameInput" autocomplete="off" readonly></el-input>
        </el-form-item>
        <el-form-item prop="plan" :label="$t('План')">
          <el-input v-model="model.plan" ref="planInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="planFixed">
              <el-checkbox v-model="model.planFixed" :label="$t('Фікс. план')" name="planFixed"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="planogram">
              <el-checkbox v-model="model.planogram" :label="$t('Планограма')" name="planogram"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditRestrictOrg",
  components: {},
  data() {
    var that = this;
    var defaultValidateRule = {};
    var digitalValueValidateRule = {
      validator: (rule, value, callback) => {
        if (value == undefined || value == "") {
          callback();
        } else if ((!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error("Поле має бути невід'ємним числом"));
        } else {
          callback();
        }
      },
    };
    return {
      loading: false,
      dialogShow: false,
      title: "Редагування налаштувань організації",
      operation: null,
      rules: { plan: [digitalValueValidateRule] },
      model: {},
      initModel: {},
    };
  },

  methods: {
    onCommit() {
      this.$refs.EditRestrictOrgForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { operation: this.operation, model: this.prepareForCommit(this.model) });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditRestrictOrgForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.$nextTick(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.planInput.focus();
        });
      });
    },
    prepareForCommit(data) {
      data.plan = data.plan && !this.isNumeric(data.plan) ? data.plan.split(",").join(".") : data.plan;
      return data;
    },
    nextFocus(next) {
      if (next == "commit") {
        this.onCommit();
      } else this.$refs[next].focus();
    },
  },
  created() {
    bus.$on("forms.rules.org.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({}, data ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on("forms.rules.org.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {},
};
</script>
<style lang="scss">
#EditRestrictOrgDialog {
  .el-dialog {
    max-height: 340px !important;
  }
}
</style>
